import { external } from '@emobg/web-api-client';

import {
  authenticateChallenge, authenticateRedirect, getOriginKey, storePaymentMethodByUUID,
} from './adyenApi.js';

jest.mock('@emobg/web-api-client', () => ({
  external: {
    paymentsProvider: {
      getOriginKey: jest.fn(),
    },
    paymentsUser: {
      getStorePaymentMethod: jest.fn(),
      postAuthenticate3DS2Redirect: jest.fn(),
      postAuthenticate3DS2Challenge: jest.fn(),
    },
  },
}));

describe('Given adyenApi', () => {
  describe('Given the "getOriginKey" method', () => {
    describe('When is called with the "origin" origin source', () => {
      it('Then the "getOriginKey" method from "Api.paymentsProvider" is called with "origin"', () => {
        const getOriginKeySpy = jest.spyOn(external.paymentsProvider, 'getOriginKey');

        getOriginKey('origin');

        expect(getOriginKeySpy).toBeCalledWith('origin');
      });
    });
  });

  describe('Given the "storePaymentMethodByUUID" method', () => {
    describe('When is called with "user" as customerType and "uuid-12345" as uuid', () => {
      it('Then the "getStorePaymentMethod" method from "Api.paymentsUser" is called with "user" and "uuid-12345"', () => {
        const storePaymentMethodByUUIDSpy = jest.spyOn(external.paymentsUser, 'getStorePaymentMethod');

        storePaymentMethodByUUID('user', 'uuid-12345');

        expect(storePaymentMethodByUUIDSpy).toBeCalledWith('user', 'uuid-12345');
      });
    });
  });

  describe('Given the "authenticateRedirect" method', () => {
    describe('When is called with "test-12345" as payload', () => {
      it('Then the "postAuthenticate3DS2Redirect" method from "Api.paymentsUser" is called with "test-12345"', () => {
        const authenticateRedirectSpy = jest.spyOn(external.paymentsUser, 'postAuthenticate3DS2Redirect');

        authenticateRedirect('test-12345');

        expect(authenticateRedirectSpy).toBeCalledWith('test-12345');
      });
    });
  });

  describe('Given the "authenticateChallenge" method', () => {
    describe('When is called with "test-12345" as payload', () => {
      it('Then the "postAuthenticate3DS2Challenge" method from "Api.paymentsUser" is called with "test-12345" and "v3"', () => {
        const authenticateChallengeSpy = jest.spyOn(external.paymentsUser, 'postAuthenticate3DS2Challenge');

        authenticateChallenge('test-12345');

        expect(authenticateChallengeSpy).toBeCalledWith('test-12345', 'v3');
      });
    });
  });
});
